import App from 'next/app';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import { ApolloProvider } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getDataFromTree } from '@apollo/client/react/ssr';
import { CloudinaryContext } from 'cloudinary-react';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import elementClosestPolyfill from 'element-closest';
import { DefaultSeo } from 'next-seo';
import Cookies from 'cookies';
import { hotjar } from 'react-hotjar';
import { useIsomorphicEffect } from 'rooks';
import withApollo from 'next-with-apollo';

import config from 'config';
import createApolloClient from 'createApolloClient';
import { AuthProvider } from 'context/Auth';
import { GlobalProvider } from 'context/Global';
import { addCustomValidators } from 'lib/validators';
import DownloadList from 'components/global/DownloadList';
import GoogleAnalytics from 'components/global/GoogleAnalytics';
import ToastList from 'components/global/ToastList';
import 'styles/style.css';

fontAwesomeConfig.autoAddCss = false;

addCustomValidators();

// eslint-disable-next-line react/prop-types
const MyApp = ({ Component, pageProps, apollo }) => {
  // eslint-disable-next-line react/prop-types
  const getLayout = Component.getLayout || ((page) => page);

  const router = useRouter();

  useIsomorphicEffect(() => {
    // Polyfill some DOMElement methods for IE11
    elementClosestPolyfill(window);

    // Enable hotjar (if configured)
    if (config('/hotjar/id')) {
      hotjar.initialize(config('/hotjar/id'), config('/hotjar/version'));
    }

    // By default, Next doesn't scroll to the top of the
    // page on manual Router interactions like .push(),
    // .replace(), and .back(), so we force it here
    router.events.on('routeChangeComplete', () => window.scrollTo(0, 0));
  }, []);

  return (
    <ApolloProvider client={apollo}>
      <CloudinaryContext cloudName={config('/cloudinary/cloudName')} secure>
        <AuthProvider>
          <GlobalProvider>
            <>
              <Head>
                <meta charSet="utf-8" key="charset" />
                <meta
                  key="viewport"
                  name="viewport"
                  content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
                />
                <link rel="shortcut icon" href="/favicon.png" />
              </Head>
              <Script src="https://upload-widget.cloudinary.com/global/all.js" />
              {config('/env') === 'production' && <GoogleAnalytics />}
              <DefaultSeo {...config('/seo')} />
              {getLayout(<Component {...pageProps} />, router, pageProps)}
              <div className="fixed z-50 bottom-0 inset-x-0 flex justify-center lg:justify-end p-8 pointer-events-none">
                <div id="snackbars" className="w-full max-w-xl -mt-1" />
              </div>
              <DownloadList />
              <ToastList />
            </>
          </GlobalProvider>
        </AuthProvider>
      </CloudinaryContext>
    </ApolloProvider>
  );
};

MyApp.getInitialProps = async (context) => {
  const result = await App.getInitialProps(context);

  const { router, ctx } = context;
  // Copy tracking query parameters to the tracking cookie if present.
  // Right now there is only "cntrk" which is for fundraiser contact tracking.
  if (ctx.req && router.query.cntrk) {
    // Cookies will be stored as a unique set of comma separated codes, each in the format "type:code"
    const cookies = new Cookies(ctx.req, ctx.res);
    const existing = (cookies.get('cntrk') ?? '').split(',').filter((x) => x !== '');
    const unique = new Set([...existing, router.query.cntrk].map((x) => `cn:${x}`));

    // We need this cookie to be available to the client because the vast number of graphql calls
    // that need the tracking data will be made from there.
    cookies.set('tracking', [...unique].join(','), { httpOnly: false });
  }

  return { ...result };
};

export default withApollo(createApolloClient)(MyApp, { getDataFromTree });
